import { exportMethodTypes } from '@src/scripts/enums'

export const EXPORT_STRUCTURE = Object.freeze({
  FORMAT: 'image/jpeg',
  QUALITY: 1,
  FILENAME: 'export.jpeg'
})

export const IGNORED_ACTION_LOGS = Object.freeze([
  'CONNECTION_STARTED',
  'CONNECTION_FINISHED'
])

export const EXPORT_NAMES = Object.freeze({
  [exportMethodTypes.thirdPartyExport]: 'CRM EXPORT'
})

export const VALID_APP_ID_FOR_VIDEO_EXPORT = Object.freeze([
  '0736CD0C-5A8D-45F8-A000-B5205FF4922D',
  '484F2F40-46D1-471E-9C2F-68014069EFE8',
  '32EAADCA-301F-4BA0-B26A-70A2AE848B32',
  '7B8C2D98-831C-49F8-AB54-2D815160EE79',
  '9526C5FE-CD23-4666-B62C-59231FD811E9',
  '892CF082-1768-4034-89A2-4083CFDA3FF4',
  '89CB8AE3-B391-4B49-AAEB-C4838AA375B7',
  '00977BA6-40B8-46D3-90CE-45FD38A6E3B1',
  '1B0DCADE-97A9-4B63-9370-803426A18E9E',
  'B3AE77FB-3F17-4A37-B5CB-0E027334D2AB',
  '60CA5C81-62CB-4112-B50D-AE737F70DE85',
  '2A6EF7CA-E4F8-4A62-B5A8-445141C149D1'
])

export const JADIS_STATUS = {
  success: 'Success',
  providerError: 'ProviderError',
  notFound: 'NotFound'
}

export const BASIC_RULES = Object.freeze([
  'SELFIE_HAS_FACE',
  'DOCUMENT_HAS_FACE',
  'DOCUMENT_HAS_MRZ',
  'DOCUMENT_HAS_BOTH_SIDES',
  'PASSIVE_LIVENESS_CHECK',
  'PROHIBITED_DOCUMENT_TYPE_CHECK',
  'DOCUMENT_PHOTO_FOUND'
])

export const MEDIA_TYPES = {
  FACE_ANIMATION: 'FACE_ANIMATION',
  DOCUMENT_PHOTO: 'DOCUMENT_PHOTO',
  FACE_VIDEO: 'FACE_VIDEO',
  FACE_PHOTO: 'FACE_PHOTO'
}

export const DOCUMENT_PHOTO_TYPE = {
  FRONT: 'FRONT',
  BACK: 'BACK'
}

export const VALIDATION_RULES = Object.freeze([
  'FACES_MATCH',
  'DOCUMENT_IS_NOT_EXPIRED',
  'PERSONAL_NUMBER_MATCH',
  'ONE_PERSON_DURING_IDENTIFICATION',
  'PERSONAL_DETAILS_MATCH',
  'NOT_PROHIBITED_BY_COUNTRY_OR_STATE',
  'DOCUMENT_DATA_IS_COHERENT',
  'SELFIE_HAS_DOCUMENT',
  'NOT_UNDER_18',
  'NOT_PROHIBITED_NATIONALITY',
  'COMPANY_REPRESENTATIVE_NO_CHECK',
  'DOCUMENT_AND_BIOMETRIC_AGE_COMPARISON_2D',
  'DOCUMENT_AND_BIOMETRIC_AGE_COMPARISON_3D',
  'BIOMETRIC_AGE_THRESHOLD_COMPARISON',
  'FIRST_LAST_DOB_CHECK',
  'PERSONAL_CODE_CHECK',
  'MRZ_VERIFIED',
  'NOT_FROM_HIGH_RISK_COUNTRY',
  'SELFIE_NFC_NOT_MATCH',
  'SELFIE_TO_NFC_MATCH',
  'DOCUMENT_HAS_NFC',
  'POA_CHECK',
  'PERSON_CODE_CHECK',
  'AGE_CHECK',
  'PersonFoundInComplyAdvantage',
  'LostAndStolen',
  'SELFIE_TO_ETALON_CHECK',
  'DOCUMENT_DETAILS_EXTRACTED_CHECK',
  'DOCUMENT_HAS_QR',
  'REGISTRATION_DATA_CHECK',
  'PROHIBITED_IPCOUNTRY'
])

export const KYC_IDENTIFICATION_POP_UP_TYPES = Object.freeze({
  IP_LIST: 'IP_LIST',
  SEON_IP: 'SEON_IP',
  SEON_EMAIL: 'SEON_EMAIL',
  STOPLIST_CONFIRM: 'STOPLIST_CONFIRM',
  MONITORING_SETUP: 'MONITORING_SETUP'
})

export const FORM_TYPES = Object.freeze({ ONDATO: 'ONDATO', BIB: 'BIB' })

export const BATCH_IMPORT_CODES_SEPARATION_REGEXP = /[\n\s,]+/

export const exceptionReasons = [
  'BIOMETRIC_AGE_DOESNT_MATCH_THRESHOLD',
  'EMPTY_AGE_ESTIMATION_RESPONSE',
  'MULTIPLE_DOCUMENTS_FOUND',
  'MISSING_REQUEST_DATA',
  'ADDRESS_NOT_FOUND',
  'TRN_INVALID'
]

export const exceptionRules = [
  'DOCUMENT_AND_BIOMETRIC_AGE_COMPARISON_3D',
  'DOCUMENT_AND_BIOMETRIC_AGE_COMPARISON_2D',
  'BIOMETRIC_AGE_THRESHOLD_COMPARISON',
  'DOCUMENT_PHOTO_FOUND',
  'JAM_TRN_ADDRESS'
]
